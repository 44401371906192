.height-width-32px {
  height: 32px;
  width: 32px;
}

.height-width-16px {
  height: 16px;
  width: 16px;
}

.flex-grow {
  flex-grow: 1;
}

.z-index-101 {
  z-index: 101;
}

@media screen and (min-width: 850px) {
  body {
    background-color: #f5f5f5;
  }

  .large-screen-bg-color {
    background-color: white;
  }

  .large-screen-padding {
    padding: 24px;
  }

  .large-screen-width {
    width: 850px;
  }

  .large-screen-height {
    height: 80%;
  }

  .large-screen-border {
    border-radius: 8px;
    border: 1px solid #e5e5e5;
  }
}

.height-2px {
  height: 2px;
}

.height-10px {
  height: 10px;
}

.height-5px {
  height: 5px;
}

.height-4px {
  height: 4px;
}

.height-16px {
  height: 16px;
}

.height-25px {
  height: 25px;
}

.height-40px {
  height: 40px;
}

.height-80px {
  height: 80px;
}

.height-120px {
  height: 120px;
}

.height-180px {
  height: 180px;
}

.height-200px {
  height: 200px;
}

.height-350px {
  height: 350px;
}

.min-height-70px {
  min-height: 70px;
}

.width-1px {
  width: 1px;
}

.width-2px {
  width: 2px;
}

.width-16px {
  width: 16px;
}

.width-30px {
  width: 30px;
}

.width-100px {
  width: 100px;
}
.width-150px {
  width: 150px;
}

.width-250px {
  width: 250px;
}

.height-width-44px {
  height: 44px;
  width: 44px;
}

.background-color-4E4E4E {
  background-color: #4e4e4e;
}

.background-color-E5E5E5 {
  background-color: #e5e5e5;
}

.border-2px-E5E5E5 {
  border: 2px solid #e5e5e5;
}

.transform-translate_0px_-5px {
  transform: translate(0px, -5px);
}

.transform-translate_0px_-10px {
  transform: translate(0px, -10px);
}

.transform-translate_0px_-15px {
  transform: translate(0px, -15px);
}

.transform-translate_0px_-28px {
  transform: translate(0px, -28px);
}

.rotate-90 {
  transform: rotate(-90deg);
}

.top-10px {
  top: 10px;
}

.top-25px {
  top: 25px;
}

.right-25px{
  right: 25px
}

.max-height-650px {
max-height: 650px;
}
